import { getUserToken } from '~/services/supabase'
import { GetPlansResponse } from '~/types/stripe/plans'

const getPlans = async () => {
  const token = await getUserToken()
  if (token) {
    const data = await fetch('/api/subscription/plans', {
      method: 'POST',
      headers: {
        'X-SUPABASE-TOKEN': token
      }
    })
    const result: GetPlansResponse = await data.json()
    return result
  }
}

export {
  getPlans
}